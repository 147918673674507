save_stat = function(project_code, stat_type, stat_value) {
	data = {
        'stat[stat_type]': stat_type,
        'stat[stat_value]': stat_value
    }

    $.ajax({
        type: 'POST',
        url: "/" + project_code + "/save_stat.js",
        data: data,
        headers: {
            'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
        },
    	success: function() {
		}
    }).fail(function (jqXHR, textStatus, error) {
		alert("save_stat ERROR\n" + error);
	});
}

//VIVISMART
vivismart_save_resource = function(res_id, value) {
    $.ajax({
        type: 'POST',
        url: "/vivismart/save_resource.js",
        data: {
            'project_resource[value]': value,
            'project_resource[res_type]': 'game',
            'project_resource[res_id]': res_id
        },
        headers: {
            'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
        },
    	success: function() {
			location.reload();
		}
    });
}

vivismart_save_resource_video = function(res_id, value) {
    $.ajax({
        type: 'POST',
        url: "/vivismart/save_resource.js",
        data: {
            'project_resource[value]': value,
            'project_resource[res_type]': 'game',
            'project_resource[res_id]': res_id
        },
		dataType : 'json',
        headers: {
            'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
        },
    	success: function(response) {
			var tappa = "tappa_" + res_id.split("_")[1];
			var next_tappa = "tappa_" + (parseInt(tappa.split("_")[1]) + 1);
			$("#div_" + res_id).addClass("terminato");
			if (response) {
				$("#div_tappa_completed").show();
				$("#div_tappa_diploma").show();

				$("#div_" + tappa + "_icon").removeClass("in-corso").addClass("conclusa");
				$("#div_" + tappa + "_icon img").attr("src", $("#div_" + tappa + "_icon img").attr("src").replace("in-corso", "conclusa"));
				if ($("#div_" + next_tappa + "_icon").length) {
					$("#div_" + next_tappa + "_icon").removeClass("da-fare").addClass("in-corso");
					$("#div_" + next_tappa + "_icon img").attr("src", $("#div_" + next_tappa + "_icon img").attr("src").replace("da-fare", "in-corso"));
					$("#div_" + next_tappa + "_icon a").removeClass("fancybox");
					$("#div_" + next_tappa + "_icon a").attr("href", "/projects/home/vivismart/" + next_tappa);
				}

				$("#div_" + tappa + "_icon_mob").removeClass("in-corso").addClass("conclusa");
				$("#div_" + tappa + "_icon_mob img").attr("src", $("#div_" + tappa + "_icon_mob img").attr("src").replace("in-corso", "conclusa"));
				if ($("#div_" + next_tappa + "_icon_mob").length) {
					$("#div_" + next_tappa + "_icon_mob").removeClass("da-fare").addClass("in-corso");
					$("#div_" + next_tappa + "_icon_mob img").attr("src", $("#div_" + next_tappa + "_icon_mob img").attr("src").replace("da-fare", "in-corso"));
					$("#div_" + next_tappa + "_icon_mob a").removeClass("fancybox");
					$("#div_" + next_tappa + "_icon_mob a").attr("href", "/projects/home/vivismart/" + next_tappa);
				}

			}
		}
    });
}